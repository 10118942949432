import React from "react";
import { motion, useScroll } from "framer-motion";
import BlogContainer from "../../components/blog/blog_container";
import BlogHeader from "../../components/blog/blog_header";
import BlogSection from "../../components/blog/blog_section";
import Navbar from "../../components/nav_bar";
import { Helmet } from "react-helmet";
import chatspherehome from "../../assets/images/chatsphere_home.png";
import chatsphereworking from "../../assets/images/chatsphere-working.png";
import chatspherelogo from "../../assets/images/chatsphere.svg";

function ChatSphereBlog() {
  const { scrollYProgress } = useScroll();

  const menuitems = [
    "Introducing ChatSphere-1",
    "Empowering Students Through Open Source Contributions",
    "ChatSphere's Vision and Future",
    "Be Part of Our Journey",
  ];

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.5, delay: 0.2 },
    },
  };

  return (
    <>
      <Helmet>
        <title>ChatSphere-1: The Open-Source AI Search Engine</title>
      </Helmet>
      <Navbar />
      <motion.div
        className="fixed top-0 left-0 right-0 h-1.5 bg-[#4382c9] z-50"
        style={{ scaleX: scrollYProgress }}
      />

      <BlogHeader
        title="Introducing ChatSphere-1: The Open-Source AI Search Engine"
        date="August 31st, 2024"
        timestamp="5 min read"
      />
      <motion.img
        src={chatspherelogo}
        alt="ChatSphere Logo"
        className="w-48 mx-auto my-8"
        variants={imageVariants}
      />

      <BlogContainer menuitems={menuitems}>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={sectionVariants}
          className="border-b border-gray-200 last:border-b-0">
          <BlogSection title="Introducing ChatSphere-1">
          <p className="text-lg mt-4">
              Check out ChatSphere at <a href="https://chatsphere.vedaverse.io" className="text-[#274C77] font-bold hover:underline">chatsphere.vedaverse.io</a> for free and start exploring the possibilities today!
            </p>
            <br />

            <p className="text-lg">
              Managing multiple AI tools and subscriptions can be both
              overwhelming and costly. That's where{" "}
              <strong>ChatSphere-1</strong> comes in—the first iteration of our
              innovative open-source AI search engine. ChatSphere-1 integrates
              all major language models (LLMs) into a single, streamlined
              platform, enabling users to access and interact with their
              favorite AI tools effortlessly. Whether you're a developer,
              researcher, or AI enthusiast, ChatSphere-1 simplifies your
              experience by providing a unified, cost-effective solution.
            </p>

            <p className="text-lg">
              <br />
              Consider the hassle of maintaining subscriptions to various AI
              services, each offering different benefits but collectively
              draining your budget. ChatSphere-1 solves this problem by
              consolidating your AI needs into one easy-to-use platform. With
              ChatSphere-1, you can access any AI model without juggling
              multiple subscriptions or switching between platforms, saving both
              time and money.
            </p>
            <motion.img
              src={chatspherehome}
              alt="ChatSphere Home Interface"
              className="w-full rounded-lg shadow-lg my-8"
              variants={imageVariants}
            />
            <motion.img
              src={chatsphereworking}
              alt="ChatSphere in Action"
              className="w-full rounded-lg shadow-lg my-8"
              variants={imageVariants}
            />
          </BlogSection>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={sectionVariants}
          className="border-b border-gray-200 last:border-b-0">
          <BlogSection title="Empowering Students Through Open Source Contributions">
            <p className="text-lg">
              At VedaVerse, we're dedicated to helping students develop
              real-world skills through practical experience. ChatSphere is more
              than just a project; it's a community-driven effort where even
              beginners can make meaningful contributions. VedaVerse students,
              many of whom had no prior experience, have already made their
              first successful contributions to ChatSphere-1. This initiative is
              a valuable opportunity for aspiring developers to gain technical
              expertise while working on a significant open-source project.
            </p>
            <p className="text-lg mt-4">
              Explore the web version on GitHub:{" "}
              <a
                href="https://github.com/VedaVerse-opensource/chatsphere"
                className="text-[#274C77] font-bold hover:underline">
                Web Version Repository
              </a>
            </p>

            <p className="text-lg mt-4">
              Try ChatSphere on web for free:{" "}
              <a
                href="https://chatsphere.vedaverse.io"
                className="text-[#274C77] font-bold hover:underline">
                chatsphere.vedaverse.io
              </a>
            </p>

            <p className="text-lg mt-4">
              Explore the mobile version on GitHub:{" "}
              <a
                href="https://github.com/vallamulla-sreeja/ChatSphere"
                className="text-[#274C77] font-bold hover:underline">
                Mobile Version Repository
              </a>
            </p>
          </BlogSection>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={sectionVariants}
          className="border-b border-gray-200 last:border-b-0">
          <BlogSection title="ChatSphere's Vision and Future">
            <p className="text-lg">
              Our long-term vision for ChatSphere is to establish it as the
              leading open-source AI search engine. The current version,
              ChatSphere-1, functions as a chatbot that integrates all major
              LLMs, providing users with quick, accurate, and comprehensive
              information. We're also developing a mobile app using React
              Native, led by a team of committed students. While the web
              platform remains our primary focus, the mobile app will extend
              ChatSphere's convenience to mobile devices, ensuring users have AI
              access anytime, anywhere.
            </p>

            <p className="text-lg mt-4">
              Check out the Figma design for ChatSphere:{" "}
              <a
                href="https://www.figma.com/design/6x6jrOQgfMoWWENXD9NFF0/ChatSphere?t=Wqg6ZHoHN64gZ5fN-1"
                className="text-[#274C77] font-bold hover:underline">
                Figma Design
              </a>
            </p>
          </BlogSection>
        </motion.div>

       
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={sectionVariants}
          className="border-b border-gray-200 last:border-b-0">
          <BlogSection title="Be Part of Our Journey">
            <p className="text-lg">
              ChatSphere-1 is more than just an AI tool; it's a collaborative
              project that captures the essence of open-source development.
              Whether you're a student looking to develop your skills or an
              experienced developer eager to contribute to AI's future,
              ChatSphere provides a unique opportunity to be part of something
              impactful. Together, we can build the ultimate AI search engine
              that benefits everyone.
            </p>
            <p className="text-lg mt-4">
              Ready to get involved? <a href="https://chatsphere.vedaverse.io" className="text-[#274C77] font-bold hover:underline">Explore ChatSphere-1 today</a> and discover how
              you can contribute to the next generation of AI technology.
            </p>
          </BlogSection>
        </motion.div>

        <footer className="p-8 rounded-b-lg shadow-lg">
          <p className="text-lg">
            Share your feedback at{" "}
            <a
              href={`mailto:ananya.pappula@gmail.com`}
              className="text-[#274C77] font-bold hover:underline">
              hello@vedaai.io
            </a>
          </p>
        </footer>
      </BlogContainer>
    </>
  );
}

export default ChatSphereBlog;
