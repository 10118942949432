import React, { useState, useEffect } from 'react';

const BlogMenu = ({ menuitems }) => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    menuitems.forEach((item) => {
      const element = document.getElementById(item.replace(/\s+/g, '-').toLowerCase());
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, [menuitems]);

  const handleClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className=' p-6 hidden md:block rounded-lg md:sticky top-5 self-start w-full md:w-1/3 lg:w-1/4'>
      <h2 className="text-xl font-bold mb-4 border-b border-white pb-2">TABLE OF CONTENTS</h2>
      <ul className="space-y-2">
        {menuitems.map((item, index) => {
          const id = item.replace(/\s+/g, '-').toLowerCase();
          return (
            <li key={index}>
              <button
                onClick={() => handleClick(id)}
                className={`text-left w-full py-1 px-2 rounded transition duration-200 ${
                  activeSection === id ? 'text-[#274C77] font-bold' : 'hover:text-[#274C77] '
                }`}
              >
                {item}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default BlogMenu;