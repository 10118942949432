import React from "react";
import Navbar from "../components/nav_bar";
import Footer from "../components/footer";

const TermsOfService = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow ">
        <div className="container mx-auto px-4 py-16 max-w-5xl">
          <h1 className="text-4xl font-bold text-[#274c77] text-center mb-8">
            Terms of Service
          </h1>
          <p className="text-gray-600 mb-6">
            Welcome to VedaVerse! By using our platform, you agree to comply
            with and be bound by the following terms and conditions. Please
            review them carefully.
          </p>

          <h2 className="text-2xl font-semibold text-[#274c77] mb-4">
            1. Acceptance of Terms
          </h2>
          <p className="text-gray-600 mb-6">
            By accessing or using VedaVerse, you agree to be bound by these
            terms and conditions and any other guidelines, rules, and additional
            terms referenced herein. If you do not agree to these terms, please
            do not use our platform.
          </p>

          <h2 className="text-2xl font-semibold text-[#274c77] mb-4">
            2. Changes to Terms
          </h2>
          <p className="text-gray-600 mb-6">
            VedaVerse reserves the right to modify or update these terms at any
            time, with or without notice. Continued use of the platform after
            any changes constitute acceptance of the new terms.
          </p>

          <h2 className="text-2xl font-semibold text-[#274c77] mb-4">
            3. User Responsibilities
          </h2>
          <p className="text-gray-600 mb-6">
            As a user, you agree to use VedaVerse responsibly and in compliance
            with all applicable laws and regulations. You are responsible for
            maintaining the confidentiality of your account and password.
          </p>

          <h2 className="text-2xl font-semibold text-[#274c77] mb-4">
            4. Privacy Policy
          </h2>
          <p className="text-gray-600 mb-6">
            Your use of VedaVerse is also governed by our Privacy Policy. Please
            review our Privacy Policy to understand our practices.
          </p>

          <h2 className="text-2xl font-semibold text-[#274c77] mb-4">
            5. Intellectual Property
          </h2>
          <p className="text-gray-600 mb-6">
            All content on VedaVerse, including but not limited to text,
            graphics, logos, and software, is the property of VedaVerse or its
            content suppliers and is protected by intellectual property laws.
          </p>

          <h2 className="text-2xl font-semibold text-[#274c77] mb-4">
            6. Termination
          </h2>
          <p className="text-gray-600 mb-6">
            VedaVerse reserves the right to terminate or suspend your account
            and access to the platform at any time, without notice, for conduct
            that we believe violates these terms or is harmful to other users of
            the platform, us, or third parties.
          </p>

          <h2 className="text-2xl font-semibold text-[#274c77] mb-4">
            7. Limitation of Liability
          </h2>
          <p className="text-gray-600 mb-6">
            In no event shall VedaVerse or its suppliers be liable for any
            direct, indirect, incidental, special, or consequential damages
            arising out of the use or inability to use the platform, even if
            VedaVerse has been advised of the possibility of such damages.
          </p>

          <h2 className="text-2xl font-semibold text-[#274c77] mb-4">
            8. Governing Law
          </h2>
          <p className="text-gray-600 mb-6">
            These terms and conditions are governed by and construed in
            accordance with the laws of [Your Jurisdiction]. You agree to submit
            to the exclusive jurisdiction of the courts located within [Your
            Jurisdiction] to resolve any legal matter arising from the terms.
          </p>

          <h2 className="text-2xl font-semibold text-[#274c77] mb-4">
            9. Contact Information
          </h2>
          <p className="text-gray-600 mb-6">
            If you have any questions about these Terms of Service, please
            contact us at support@vedaverse.com.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default TermsOfService;
