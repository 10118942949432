import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import HomePage from "./pages/home_page";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import BlogHome from "./pages/blog_home";
import IntroductoryBlog from "./pages/blogs/introductory_blog";
import { Analytics } from "@vercel/analytics/react";
import AboutPage from "./pages/about_us";
import ContactPage from "./pages/contact_us";
import TermsOfService from "./pages/terms_of_service";
import ChatSphereBlog from "./pages/blogs/chatsphere_blog";
import HackathonPage from "./pages/hackathon/hackathon_page";

Sentry.init({
  dsn: "https://8c162ce46d5fd92f162ce69f4e00dba5@o4507240784199680.ingest.us.sentry.io/4507240795996160",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/VedaVerse\.com/,
    /^https:\/\/api\.VedaVerse\.com\/api/,
  ],
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1.0,
});

const App = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.tailwindcss.com";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
          draggable
        />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/blogs" element={<BlogHome />} />
          <Route
            path="/blog/introducing-vedaverse"
            element={<IntroductoryBlog />}
          />
          <Route
            path="/blog/introducing-chatsphere-1"
            element={<ChatSphereBlog />}
          />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/hackathon" element={< HackathonPage/>} />
        </Routes>
        <Analytics />
      </Router>
    </ThemeProvider>
  );
};

export default App;
