import React from "react";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const AboutCard = ({
  name,
  image,
  designation,
  description,
  linkedIn,
  twitter,
  github,
}) => {
  return (
    <div className="bg-white rounded-lg border border-gray-200 overflow-hidden m-4 max-w-sm flex flex-col">
      <img
        className="object-cover object-center w-full"
        src={image}
        alt={name}
      />
      <div className="flex flex-col flex-grow h-full p-6">
        <h2 className="text-3xl font-bold mb-2 text-gray-800">{name}</h2>
        <p className="text-gray-600 font-semibold text-sm mb-4">
          {designation}
        </p>
        <p className="text-black leading-relaxed text-balance mb-4 tracking-wide flex-grow">
          {description}
        </p>
        <div className="flex justify-center space-x-4 mt-auto">
          {linkedIn && (
            <a
              href={linkedIn}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800">
              <FaLinkedin size={28} />
            </a>
          )}
          {twitter && (
            <a href={twitter} target="_blank" rel="noopener noreferrer">
              <FaSquareXTwitter size={28} />
            </a>
          )}
          {github && (
            <a
              href={github}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-800 hover:text-gray-600">
              <FaGithub size={28} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutCard;
