import React from "react";
import {
  Typography,
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Card,
  CardContent,
  CardMedia,
  Accordion,
  Divider,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import { styled } from "@mui/system";
import { Helmet } from "react-helmet";
import {
  School,
  SportsEsports,
  Psychology,
  Accessibility,
  Email,
  Phone,
  ExpandMore,
} from "@mui/icons-material";
import { motion, AnimatePresence } from "framer-motion";
import vedaverseLogo from "../../assets/logo/logo.png";
import gnitsLogo from "../../assets/images/gnits.png";
import Navbar from "../../components/nav_bar";

const HeroSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  backgroundSize: "cover",
  backgroundPosition: "center",
  color: "#ffffff",
  padding: theme.spacing(10, 2),
  textAlign: "center",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(4),
}));

const ThemeItem = styled(motion.div)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "30px",
  padding: theme.spacing(1.5, 4),
  fontWeight: "bold",
  textTransform: "uppercase",
  boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
  transition: "all 0.15s ease",
  "&:hover": {
    transform: "translateY(-1px)",
    boxShadow:
      "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)",
  },
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    margin: theme.spacing(2, 0),
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "&.Mui-expanded": {
    minHeight: 56,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  "&:not(:last-child)": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const CountdownTimer = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <Box key={interval} sx={{ mx: 2 }}>
        <Typography variant="h4">{timeLeft[interval]}</Typography>
        <Typography variant="body2">{interval}</Typography>
      </Box>
    );
  });

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
      {timerComponents.length ? (
        timerComponents
      ) : (
        <Typography>The hackathon has started!</Typography>
      )}
    </Box>
  );
};

const HackathonPage = () => {
  const hackathonDate = new Date("2024-09-25T00:00:00");

  const [expandedPanel, setExpandedPanel] = React.useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  const subThemes = [
    {
      title: "Bridging the Gap",
      description:
        "Create solutions that connect academic learning with industry needs, preparing students for real-world challenges.",
      icon: <School fontSize="large" color="primary" />,
    },
    {
      title: "Gamification in Education",
      description:
        "Develop engaging, game-based learning experiences that make education fun and interactive.",
      icon: <SportsEsports fontSize="large" color="primary" />,
    },
    {
      title: "AI in Assessment",
      description:
        "Leverage AI to provide personalized, real-time feedback and assessment in educational contexts.",
      icon: <Psychology fontSize="large" color="primary" />,
    },
    {
      title: "Inclusive Education",
      description:
        "Design innovative solutions that make education accessible to all, regardless of physical or cognitive abilities.",
      icon: <Accessibility fontSize="large" color="primary" />,
    },
  ];

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const pulseAnimation = {
    scale: [1, 1.05, 1],
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatType: "reverse",
    },
  };

  const participationGuidelines = [
    {
      title: "Team Formation",
      details: [
        "Teams can contain a maximum of 4 members.",
        "Participants can form teams prior to the event or use our team formation platform to find teammates.",
        "Each team is recommended to have a diverse skill set (e.g., programming, design, business).",
      ],
    },
    {
      title: "Project Requirements",
      details: [
        "You can use open-source libraries and APIs.",
        "Your project must address one of the given sub-themes.",
        "Submissions should include source code and an overview of the project.",
      ],
    },
    {
      title: "Code of Conduct",
      details: [
        "Treat all participants, mentors, and organizers with respect and courtesy.",
        "Any form of harassment, discrimination, or inappropriate behavior will not be tolerated.",
        "Collaborate and compete in the spirit of learning and innovation.",
        "If you experience or witness any violation of the code of conduct, report it immediately to the organizers.",
      ],
    },
    {
      title: "Technical Requirements",
      details: [
        "Ensure you have a stable internet connection for the duration of the event.",
        "You are free to use any programming language or technology stack of your choice.",
        "Have your development environment set up before the hackathon starts.",
        "Make sure you have access to video conferencing tools (e.g., Zoom) for team collaboration and presentations.",
      ],
    },
    {
      title: "Submission and Judging",
      details: [
        "All projects must be submitted by the deadline announced during the event.",
        "Late submissions will not be accepted under any circumstances.",
        "Be prepared to demonstrate a working prototype or MVP (Minimum Viable Product).",
        "Judging will be based on innovation, impact, feasibility, and presentation quality.",
        "The judges' decision is final and no correspondence will be entered into regarding the judging process.",
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>EduHacks 1.0 - VedaVerse Inaugural Hackathon</title>
        <meta
          name="description"
          content="Join EduHacks 1.0, a 24-hour virtual hackathon focusing on Ed-Tech innovation. Develop impactful solutions and shape the future of education with VedaVerse and GNITS!"
        />
      </Helmet>
      <Navbar />
      <Box>
        <HeroSection>
          <Container maxWidth="lg">
            <motion.div
              initial="hidden"
              animate="visible"
              variants={staggerChildren}>
              <motion.div variants={fadeInUp}>
                <Typography
                  variant="h1"
                  gutterBottom
                  sx={{
                    fontSize: { xs: "2.5rem", md: "3.75rem" },
                    fontWeight: "bold",
                    color: "#ffffff",
                  }}>
                  EduHacks 1.0
                </Typography>
              </motion.div>
              <motion.div variants={fadeInUp}>
                <Box sx={{ borderRadius: "10px", p: 3, mb: 3 }}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "1.25rem", md: "1.75rem" },
                      fontWeight: "normal",
                      color: "#ffffff",
                    }}>
                    Hackathon starts on 5th Oct, 2024 for 24 hours
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: { xs: "1rem", md: "1.25rem" },
                      fontWeight: "normal",
                      color: "#ffffff",
                    }}>
                    Registration Closes on 25th Sept, 2024
                  </Typography>
                  <br></br>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: { xs: "1rem", md: "1.25rem" },
                      fontWeight: "normal",
                      color: "#ffffff",
                    }}>
                    Mode: Online
                  </Typography>
                </Box>
              </motion.div>
              <motion.div variants={fadeInUp}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{
                    fontSize: { xs: "1.25rem", md: "1.5rem" },
                    fontWeight: "normal",
                    color: "#ffffff",
                  }}>
                  Registration ends in
                </Typography>
                <CountdownTimer targetDate={hackathonDate} />
              </motion.div>
              <motion.div
                variants={fadeInUp}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}>
                <Box
                  sx={{
                    mt: 4,
                    display: "inline-block",
                  }}>
                  <StyledButton
                    variant="contained"
                    color="secondary"
                    size="large"
                    href="https://forms.gle/YWuQuhBTrQEJnfH3A"
                    target="_blank"
                    sx={{
                      borderRadius: "10px",
                      backgroundColor: "#ffffff",
                      color: "#001F3F",
                      fontWeight: "bold",
                      padding: "12px 24px",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                      },
                    }}>
                    Register Now
                  </StyledButton>
                </Box>
              </motion.div>
            </motion.div>
          </Container>
        </HeroSection>

        <Container maxWidth="lg" sx={{ my: 8 }}>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={staggerChildren}>
            <motion.div variants={fadeInUp}>
              <SectionTitle variant="h3">About EduHacks 1.0</SectionTitle>
              <Typography variant="body1" paragraph>
                Welcome to VedaVerse's inaugural hackathon, EduHacks 1.0! This
                24-hour virtual event brings together innovative minds to tackle
                real-world challenges in the Ed-Tech space. Join us on October
                5-6, 2024, for an unforgettable experience of creativity,
                collaboration, and cutting-edge technology.
              </Typography>
              <Typography variant="body1" paragraph>
                EduHacks 1.0 is your opportunity to make a lasting impact on the
                future of learning. Develop solutions that could revolutionize
                education and potentially reach learners worldwide through the
                VedaVerse platform.
              </Typography>
            </motion.div>

            <motion.div variants={fadeInUp}>
              <SectionTitle variant="h3" sx={{ mt: 6 }}>
                Main Theme: Ed-Tech Space
              </SectionTitle>
              <Typography variant="body1" paragraph>
                Our main theme, "Ed-Tech Space," invites you to explore the
                future of education through technology. We challenge you to
                create innovative solutions that revolutionize learning
                experiences, making education more accessible, engaging, and
                effective for learners worldwide.
              </Typography>

              <SectionTitle variant="h4" sx={{ mt: 4 }}>
                Sub-themes
              </SectionTitle>
              <Grid container spacing={4}>
                {subThemes.map((theme, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <motion.div
                      variants={fadeInUp}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      style={{ height: "100%" }}>
                      <ThemeItem>
                        <IconContainer>
                          <motion.div animate={pulseAnimation}>
                            {theme.icon}
                          </motion.div>
                        </IconContainer>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ mb: 2, textAlign: "center" }}>
                          {theme.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {theme.description}
                        </Typography>
                      </ThemeItem>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </motion.div>

            <motion.div variants={fadeInUp}>
              <Paper
                elevation={3}
                sx={{
                  mt: 8,
                  p: 4,
                  backgroundColor: "primary.main",
                  color: "primary.contrastText",
                  borderRadius: 4,
                }}>
                <SectionTitle
                  variant="h3"
                  sx={{ textAlign: "center", color: "white" }}>
                  Prizes & Awards
                </SectionTitle>
                <Grid container spacing={4} justifyContent="center">
                  {["1st Place", "2nd Place", "3rd Place"].map(
                    (place, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: index * 0.2 }}
                          whileHover={{
                            scale: 1.05,
                            transition: { duration: 0.3 },
                          }}>
                          <ThemeItem
                            sx={{
                              border: `2px solid ${
                                index === 0
                                  ? "gold"
                                  : index === 1
                                  ? "silver"
                                  : "#cd7f32"
                              }`,
                              backgroundColor: "rgba(255, 255, 255, 0.1)",
                              backdropFilter: "blur(10px)",
                              transition: "all 0.3s ease-in-out",
                              "&:hover": {
                                boxShadow: `0 0 20px ${
                                  index === 0
                                    ? "gold"
                                    : index === 1
                                    ? "silver"
                                    : "#cd7f32"
                                }`,
                                transform: "translateY(-10px)",
                                backgroundColor: `rgba(${
                                  index === 0
                                    ? "255, 215, 0"
                                    : index === 1
                                    ? "192, 192, 192"
                                    : "205, 127, 50"
                                }, 0.2)`,
                              },
                            }}>
                            <Box
                              sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "center",
                                textAlign: "center",
                              }}>
                              <Typography
                                variant="h4"
                                gutterBottom
                                sx={{
                                  color: "primary.contrastText",
                                  fontWeight: "bold",
                                  transition: "all 0.3s ease-in-out",
                                }}>
                                {place}
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "primary.contrastText",
                                  transition: "all 0.3s ease-in-out",
                                }}>
                                {
                                  ["Rs. 10,000", "Rs. 7,000", "Rs. 3,000"][
                                    index
                                  ]
                                }
                              </Typography>
                            </Box>
                          </ThemeItem>
                        </motion.div>
                      </Grid>
                    )
                  )}
                </Grid>

                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ textAlign: "center", mt: 6 }}>
                  Additional Opportunities
                </Typography>
                <Grid container spacing={4} sx={{ mt: 2 }}>
                  {[
                    {
                      title: "Project Development",
                      description:
                        "3 projects will be selected for further development",
                    },
                    {
                      title: "Swag for All",
                      description: "All participants receive exclusive swag",
                    },
                    {
                      title: "Career Opportunities",
                      description: "Internship and campus ambassador roles",
                    },
                  ].map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        style={{ height: "100%" }}>
                        <Box
                          sx={{
                            textAlign: "center",
                            p: 2,
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                            borderRadius: 2,
                            backdropFilter: "blur(10px)",
                          }}>
                          <Typography
                            variant="h6"
                            gutterBottom
                            color="primary.contrastText">
                            {item.title}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="primary.contrastText">
                            {item.description}
                          </Typography>
                        </Box>
                      </motion.div>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </motion.div>

            <motion.div variants={fadeInUp}>
              <SectionTitle variant="h3" sx={{ mt: 6 }}>
                VedaVerse's Commitment to Innovation
              </SectionTitle>
              <Typography variant="body1" paragraph>
                At EduHacks 1.0, every idea has the potential to transform
                education. VedaVerse is committed to nurturing promising
                innovations beyond the hackathon. If your team presents a
                concept that addresses critical needs in education, VedaVerse
                may select your project for further development and integration
                into our platform. A minimum of one project under the subtheme
                &nbsp;
                <strong>'Inclusive Education'</strong> will be selected.
              </Typography>
              <Typography variant="body1" paragraph>
                This unique opportunity is open to all participants, giving
                every team the chance to see their ideas come to life and make a
                real impact in the world of education through VedaVerse's
                growing ecosystem.
              </Typography>
            </motion.div>
            <motion.div variants={fadeInUp}>
              <SectionTitle
                variant="h3"
                sx={{ mt: 6, mb: 4, textAlign: "center" }}>
                Judging Criteria
              </SectionTitle>
              <Grid container spacing={3} justifyContent="center">
                {[
                  {
                    primary: "Innovation",
                    secondary: "How unique and innovative is your solution?",
                    icon: "💡",
                  },
                  {
                    primary: "Impact",
                    secondary:
                      "What is the potential impact of your solution on the Ed-Tech space?",
                    icon: "🌟",
                  },
                  {
                    primary: "Feasibility",
                    secondary:
                      "How practical and feasible is your solution to implement?",
                    icon: "🛠️",
                  },
                  {
                    primary: "Presentation",
                    secondary:
                      "How well do you present your idea and solution to the judges?",
                    icon: "🎭",
                  },
                ].map((item, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <motion.div
                      variants={fadeInUp}
                      whileHover={{ scale: 1.05 }}
                      style={{ height: "100%" }}>
                      <Box
                        sx={{
                          height: "100%",
                          p: 3,
                          border: "1px solid",
                          borderColor: "primary.main",
                          borderRadius: 2,
                          transition: "all 0.3s ease",
                          "&:hover": {
                            borderColor: "secondary.main",
                            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                            "& .MuiTypography-h5": {
                              color: "secondary.main",
                            },
                          },
                        }}>
                        <Typography variant="h2" align="center" sx={{ mb: 2 }}>
                          {item.icon}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="primary"
                          align="center"
                          sx={{ mb: 2 }}>
                          {item.primary}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          align="center">
                          {item.secondary}
                        </Typography>
                      </Box>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </motion.div>

            <motion.div variants={fadeInUp}>
              <SectionTitle variant="h3" sx={{ mt: 6 }}>
                Selection for Further Development
              </SectionTitle>
              <Typography variant="body1" paragraph>
                VedaVerse will evaluate all projects based on their potential
                impact, innovation, and alignment with our mission to enhance
                global education. Projects selected for further development will
                be chosen based on:
              </Typography>
              <List>
                {[
                  "Potential to address significant challenges in education",
                  "Innovative use of technology",
                  "Scalability and long-term sustainability",
                  "Alignment with VedaVerse's educational ecosystem",
                  "Recognition as original creators within VedaVerse",
                ].map((item, index) => (
                  <motion.div key={index} variants={fadeInUp}>
                    <ListItem>
                      <ListItemIcon>
                        <Typography variant="h6" color="primary">
                          •
                        </Typography>
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  </motion.div>
                ))}
              </List>
            </motion.div>

            <motion.div variants={fadeInUp}>
              <SectionTitle variant="h3" sx={{ mt: 4, mb: 3 }}>
                Participation Guidelines
              </SectionTitle>
              {participationGuidelines.map((guideline, index) => (
                <React.Fragment key={index}>
                  <StyledAccordion
                    expanded={expandedPanel === `panel${index}`}
                    onChange={handleAccordionChange(`panel${index}`)}>
                    <StyledAccordionSummary
                      expandIcon={
                        <ExpandMore sx={{ color: "primary.contrastText" }} />
                      }
                      aria-controls={`panel${index}a-content`}
                      id={`panel${index}a-header`}>
                      <Typography variant="h6">{guideline.title}</Typography>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                      <AnimatePresence>
                        {expandedPanel === `panel${index}` && (
                          <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.3 }}>
                            <List disablePadding>
                              {guideline.details.map((detail, detailIndex) => (
                                <StyledListItem key={detailIndex}>
                                  <ListItemText
                                    primary={detail}
                                    primaryTypographyProps={{
                                      variant: "body1",
                                      sx: { fontWeight: "medium" },
                                    }}
                                  />
                                </StyledListItem>
                              ))}
                            </List>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </StyledAccordionDetails>
                  </StyledAccordion>
                  {index < participationGuidelines.length - 1 && (
                    <Divider sx={{ my: 0 }} />
                  )}
                </React.Fragment>
              ))}
            </motion.div>
            <motion.div variants={fadeInUp}>
              <SectionTitle variant="h3" sx={{ mt: 6 }}>
                Contact Information
              </SectionTitle>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Email color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="body1">sreeja.v@vedaverse.io</Typography>
                </Grid>
              </Grid>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Need more info? Have a burning question? Or maybe you need
                special arrangements because your genius brain requires a steady
                supply of pizza? Don't hesitate to reach out! We're here to
                help, and we promise we won't judge your midnight coding snack
                choices. Drop us a line, and we'll get back to you faster than
                you can say "Hello, World!"
              </Typography>
            </motion.div>

            <motion.div variants={fadeInUp}>
              <SectionTitle variant="h3" sx={{ mt: 6 }}>
                FAQ
              </SectionTitle>
              <Grid container spacing={4}>
                {[
                  {
                    question: "Who can participate?",
                    answer:
                      "To maintain fairness, this hackathon is open to students only. However, we're excited to announce that we'll be conducting more hackathons soon which will be open to all, including professionals and enthusiasts. Stay tuned for future opportunities to participate!",
                  },
                  {
                    question: "Is there a participation fee?",
                    answer:
                      "Participation is completely free! We believe in making innovation accessible to all.",
                  },
                  {
                    question:
                      "What happens if my project is selected for further development?",
                    answer:
                      "If your project shows promise, VedaVerse will work with your team to integrate it into our educational platform. You'll have the opportunity to see your idea reach learners worldwide while gaining valuable experience in EdTech development.",
                  },
                  {
                    question: "How can I stay updated?",
                    answer:
                      "Visit our website vedaverse.io for detailed guidelines and updates. We'll also send important information via email, so make sure to check your inbox regularly.",
                  },
                ].map((faq, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <motion.div
                      variants={fadeInUp}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      style={{ height: "100%" }}>
                      <ThemeItem
                        sx={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}>
                        <Typography variant="h6" gutterBottom>
                          {faq.question}
                        </Typography>
                        <Typography variant="body2" sx={{ flexGrow: 1 }}>
                          {faq.answer}
                        </Typography>
                      </ThemeItem>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </motion.div>
            <motion.div variants={fadeInUp}>
              <SectionTitle variant="h3" align="center" sx={{ mt: 8 }}>
                Organized by
              </SectionTitle>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={4}>
                <Grid item>
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    transition={{ type: "spring", stiffness: 300 }}>
                    <Box sx={{ textAlign: "center" }}>
                      <img
                        src={vedaverseLogo}
                        alt="VedaVerse Logo"
                        style={{ height: 100, objectFit: "contain" }}
                      />
                      <Typography variant="h6" sx={{ color: "primary.main", mt: 2 }}>
                        VedaVerse
                      </Typography>
                    </Box>
                  </motion.div>
                </Grid>
                <Grid item>
                  <Typography variant="h6">in partnership with</Typography>
                </Grid>
                <Grid item>
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    transition={{ type: "spring", stiffness: 300 }}>
                    <Box sx={{ textAlign: "center" }}>
                      <img
                        src={gnitsLogo}
                        alt="GNITS Logo"
                        style={{ height: 100, objectFit: "contain" }}
                      />
                      <Typography variant="h6" sx={{ color: "primary.main", mt: 2 }}>
                        AIC-GNITS
                      </Typography>
                    </Box>
                  </motion.div>
                </Grid>
              </Grid>
            </motion.div>

            <motion.div
              variants={fadeInUp}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}>
              <Box sx={{ mt: 6, textAlign: "center" }}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  size="large"
                  href="https://forms.gle/YWuQuhBTrQEJnfH3A"
                  target="_blank">
                  Join EduHacks 1.0: Innovate for Global Education
                </StyledButton>
              </Box>
            </motion.div>
          </motion.div>
        </Container>
      </Box>
    </>
  );
};

export default HackathonPage;
