import React from "react";
import { Helmet } from "react-helmet";
import AboutCard from "../components/about/about_card";
import Navbar from "../components/nav_bar";
import Footer from "../components/footer";
import profhalf from "../assets/images/prof.png";
import ProfCard from "../components/about/prof_card";

const founders = [
  {
    name: "Sai Veda",
    image:
      "https://media.licdn.com/dms/image/v2/D5603AQFO1j13Lt_jWQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1719108065525?e=1728518400&v=beta&t=3IgQciwHoPgjraP-Ij3WfiqUlKfE-pB6anA_EOjoqAs",
    designation: "Co-Founder",
    description:
      "Sai's journey began as a med school dropout with a background in business administration. Driven by curiosity, he taught himself programming before formally pursuing a master's degree in Computer Science. This unique path inspired the creation of VedaVerse, an AI professor personalized to teach. As a software developer with five years of experience, Sai has built impactful projects, including a mobile app during the COVID-19 pandemic that connected plasma donors with patients, recognized by the Governor of Telangana. In 2020, he founded his first startup, applying his multidisciplinary background to address real-world challenges through technology.",
    linkedIn: "https://www.linkedin.com/in/saiveda/",
    twitter: "https://x.com/masettyveda",
    github: "https://github.com/sai-veda",
  },
  {
    name: "Sreeja Vallamulla",
    image: "https://avatars.githubusercontent.com/u/67339132?v=4",
    designation: "Co-Founder",
    description:
      "Sreeja brings over 3 years of software development experience from her time at a multinational corporation. With a master's degree in her field, she possesses a strong theoretical foundation complemented by practical industry knowledge. Her journey through traditional education left her feeling underprepared for the realities of professional software development, an experience that resonated with her co-founders. This shared frustration with conventional learning methods became the catalyst for creating VedaVerse. Sreeja's proficiency spans a wide range of technologies, enabling her to architect and build scalable products. Her comprehensive skill set and vision for more effective tech education drive VedaVerse's mission to bridge the gap between academic learning and industry requirements.",
    linkedIn: "https://www.linkedin.com/in/sreeja-vallamulla/",
    github: "https://github.com/sreeja-vallamulla",
  },
  {
    name: "Bob",
    image:
      "https://media.licdn.com/dms/image/D5603AQFTQsQaM9r0ew/profile-displayphoto-shrink_800_800/0/1690003940639?e=1728518400&v=beta&t=6M88jezDotRoaOtnpMMrF-b3qFOvbzZ0pxGHKADLQOQ",
    designation: "Co-Founder",
    description:
      "Bob Karr, a seasoned entrepreneur and angel investor, brings over 25 years of experience as Founder of LinkSV to VedaVerse. With a rich background in connecting startups with capital and talent, Bob's expertise spans sales management and business development. A graduate of the University of the Pacific, he serves as a guest lecturer at Santa Clara University, mentoring graduate students. Being a non-technical co-founder, Bob's extensive startup experience and deep understanding of the Silicon Valley ecosystem make him invaluable to VedaVerse. Driven by a belief in making the world a better place, he joined VedaVerse to effect meaningful change, aligning his vision with the company's mission to revolutionize education through technology.",
    linkedIn: "https://www.linkedin.com/in/bobkarr/",
  },
];

const teamMembers = [
  {
    name: "Ananya Pappula",
    image:
      "https://media.licdn.com/dms/image/v2/D5603AQGpVgbOdnQW7Q/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1701854059151?e=1728518400&v=beta&t=Q8Pm-cAw86xPKBgelm_TFrI7ipKNb3kyhQbAyms6KMk",
    designation: "Intern",
    description:
      "Ananya Pappula, a third-year B.Tech student at CVR College of Engineering, brings fresh energy and diverse talents to VedaVerse as its first intern. Ananya's achievements extend beyond academics, as evidenced by her team's victory in the BitNBuild hackathon and a consolation prize in the National Level Ideathon, IDEC 2024. As the Executive Co-lead of her college's technical club (Cybernauts) and the Core Committee member of the Literature and Debate club, she balances her technical pursuits with creative interests. Ananya's exploration of web development and AI/ML positions her well to contribute to VedaVerse's innovative projects.",
    linkedIn: "https://www.linkedin.com/in/ananya-pappula/",
    github: "https://github.com/ananya54321",
  },
  {
    name: "Sumir Vats",
    image:
      "https://media.licdn.com/dms/image/D5603AQHfKz8L2qg8Eg/profile-displayphoto-shrink_400_400/0/1713695940631?e=1728518400&v=beta&t=AxKjnFRCkxrz6olmPRXQWeoC2My6HWiWmSP5_dS19pw",
    designation: "Intern",
    description:
      "Sumir Vats, a final year Computer Engineering student at Aligarh Muslim University, brings valuable open-source experience to VedaVerse. As the overseer of the company's open-source initiatives, his background in web and desktop development, algorithm design, and competitive programming enhances VedaVerse's technical capabilities. Sumir's leadership experience as a former Google Developer Students Club lead demonstrates his ability to drive collaborative projects and mentor peers. His passion for innovation and commitment to open-source principles align perfectly with VedaVerse's mission to revolutionize education through technology.",
    linkedIn: "https://www.linkedin.com/in/sumirvats/",
    github: "https://github.com/sumirx",
  },
];

const aiProf = {
  name: "AI Professor",
  image: profhalf,
  designation: "Professor",
  description:
    "At the heart of VedaVerse is our AI Professor, who is designed to adapt to each student's unique skill set, offering a learning experience that feels as real as a face-to-face lesson. With multimodal capabilities and support in multiple languages, our AI Professor crafts tailored educational journeys, ensuring that every learner can achieve their full potential.",
  linkedIn: "https://www.linkedin.com/company/vedaverse-io/",
  github: "https://github.com/s-veda",
};

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>VedaVerse | About</title>
      </Helmet>
      <Navbar />
      <div className="min-h-screen">
        <div className="container mx-auto px-4 py-16">
          <AboutSection />
          <FoundersSection />
          <TeamSection />
        </div>
      </div>
      <Footer />
    </>
  );
};

const AboutSection = () => (
  <div className="flex flex-col  items-center justify-between  mb-16">
    <div className="flex flex-col text-balance md:w-full text-center mb-8 md:mb-20">
      <h1 className="text-5xl md:mb-10 font-bold text-[#274C77] mb-6">
        About VedaVerse
      </h1>
      <p className="text-xl text-gray-700 leading-relaxed">
        Our mission is to provide high-quality, personalized education that's
        accessible to everyone, regardless of their background or location.
        Beyond this, VedaVerse emphasizes practical, real-world experience,
        encouraging students to engage in open-source projects that prepare them
        for professional success. We also foster a global community of learners,
        promoting collaboration and support across cultures.
      </p>
    </div>
    <div className="md:mr-16 flex w-full justify-center items-center">
      <ProfCard {...aiProf} />
    </div>
  </div>
);

const FoundersSection = () => (
  <>
    <h1 className="text-2xl font-semibold text-center mb-16 text-[#274C77]">
      MEET THE FOUNDERS
    </h1>
    <div className="flex flex-wrap justify-center mb-40">
      {founders.map((member, index) => (
        <AboutCard key={index} {...member} />
      ))}
    </div>
    <hr />
  </>
);

const TeamSection = () => (
  <>
    <h1 className="text-2xl font-semibold text-center text-[#274C77] m-16">
      MEET THE TEAM
    </h1>
    <div className="flex flex-wrap justify-center">
      {teamMembers.map((member, index) => (
        <AboutCard key={index} {...member} />
      ))}
    </div>
  </>
);

export default AboutPage;
