import React, { useEffect } from 'react';
import BlogMenu from './blog_menu';

const BlogContainer = ({ children, className, menuitems }) => {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://cdn.tailwindcss.com';
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, []);

    return (
      <div className=" min-h-screen text-[18px] py-12 px-4 sm:px-6 lg:px-8 w-full font-['Mulish',sans-serif]">
        <style>{`
          @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
        `}</style>
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row gap-8">
          <BlogMenu menuitems={menuitems} />
          
          <div className={`bg-white rounded-lg overflow-hidden flex-grow md:w-2/3 lg:w-3/4 ${className}`}>
            {children}
          </div>
        </div>
      </div>
    );
  };

export default BlogContainer;