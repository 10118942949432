import React, { useState } from "react";
import { ChevronDown } from "lucide-react";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is VedaVerse?",
      answer:
        "VedaVerse is an innovative ed-tech platform that offers high-quality personalized education through an AI professor. It creates custom curricula based on each student's learning goals and needs, provides interactive learning experiences with real-time educational videos, and emphasizes practical, real-world experience through open-source projects.",
    },
    {
      question: "How does VedaVerse work?",
      answer: `
        1. Sign up for the platform
        2. Engage in a conversation with the AI professor about your learning goals
        3. Receive a personalized curriculum based on your requirements
        4. Access real-time interactive video lessons for each topic
        5. Complete practical contributions at the end of each section
      `,
    },
    {
      question: "Who is the AI professor?",
      answer:
        "The AI professor is an advanced AI avatar that interacts with students, understands their learning needs, and creates personalized curricula. It's designed to provide a tailored learning experience for each user.",
    },
    {
      question: "How is my curriculum created?",
      answer:
        "Your curriculum is generated dynamically based on the conversation you have with the AI professor. The AI analyzes key components from your discussion to create a learning path that matches your specific requirements and goals.",
    },
    {
      question: "Are the video lessons pre-recorded?",
      answer:
        "No, the video lessons are dynamically generated through AI technology. This ensures that the content is up-to-date and tailored to your personalized curriculum.",
    },
    {
      question: "What are 'practical contributions'?",
      answer:
        "Practical contributions are hands-on projects or assignments that you complete at the end of each section. These help reinforce your learning and allow you to apply the skills you've acquired in practical scenarios.",
    },
    {
      question: "How does VedaVerse help build my profile?",
      answer:
        "By completing courses and practical contributions on VedaVerse, you're actively building a portfolio of skills and projects. This can be valuable for showcasing your abilities to potential employers or clients.",
    },
    {
      question: "Can I change my learning path once I've started?",
      answer:
        "Of Course! While the initial curriculum is based on your conversation with the AI professor, you can adjust your learning path as you progress. Simply communicate your new goals and interests to the AI professor, who will then adjust your curriculum accordingly. ",
    },
    {
      question: "Is VedaVerse suitable for all skill levels?",
      answer:
        "Yes, VedaVerse is designed to accommodate learners at various skill levels. The AI professor tailors the curriculum based on your current knowledge and learning goals.",
    },
    {
      question:
        "How does VedaVerse differ from traditional online learning platforms?",
      answer: `
        VedaVerse stands out by offering:
        - Personalized curricula created by an AI professor
        - Dynamic, AI-generated video content
        - Practical, hands-on contributions for applied learning
        - A unique conversational approach to understanding your learning needs
      `,
    },
    {
      question: "How often is the content updated?",
      answer:
        "Since the video content is dynamically generated in real-time, it can incorporate the latest information and developments in your field of study.",
    },
    {
      question: "How do I get started with VedaVerse?",
      answer:
        "Simply sign up on our website and start a conversation with the AI professor about what you'd like to learn. The platform will guide you through the rest of the process.",
    },
    {
      question: "Will I get a certificate upon course completion?",
      answer:
        "Yes, VedaVerse provides a certificate upon course completion. This certificate will include links and descriptions of all the projects you completed during the course.",
    },
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <main className="flex-grow">
      <div className="container mx-auto px-4 py-16 max-w-5xl">
        <h1 className="text-4xl font-bold text-[#274c77] text-center m-16">
          Frequently Asked Questions
        </h1>
        <p className="text-center text-gray-600 mb-12 text-lg md:px-20">
          These are some of the most common questions we get asked. If you have
          a question that is not answered here, please feel free to contact us.
        </p>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="border border-gray-200 rounded-lg overflow-hidden">
              <button
                className="w-full text-left p-4 focus:outline-none bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
                onClick={() => handleToggle(index)}>
                <div className="flex justify-between items-center">
                  <h2 className="text-lg font-medium text-[#274c77]">
                    {faq.question}
                  </h2>
                  <ChevronDown
                    className={`w-5 h-5 text-[#274c77] transition-transform duration-200 ${
                      activeIndex === index ? "transform rotate-180" : ""
                    }`}
                  />
                </div>
              </button>
              <div
                className={`overflow-hidden transition-all duration-300 ease-in-out ${
                  activeIndex === index ? "max-h-96" : "max-h-0"
                }`}>
                <p className="p-4 text-gray-600 whitespace-pre-line bg-white">
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default Faq;
