import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo/logo-white.png";
import twitter from "../assets/images/twitter.svg";
import linkedin from "../assets/images/linkedin.png";

const Footer = () => {
  const footerSections = [
    {
      title: "Company",
      links: [
        { name: "About Us", url: "/about" },
        { name: "Blog", url: "/blogs" },
      ],
    },
    {
      title: "Resources",
      links: [
        { name: "Contact", url: "/contact" },
        // { name: "FAQ", url: "/faq" },
      ],
    },
    {
      title: "Legal",
      links: [
        { name: "Terms of Service", url: "/terms-of-service" },
        // { name: "Privacy Policy", url: "#" },
        // { name: "Cookie Policy", url: "#" },
      ],
    },
    {
      title: "Connect",
      links: [
        {
          name: (
            <div className="flex items-center">
              <img src={twitter} alt="Twitter" className="w-5 h-5 mr-2" />
              Twitter
            </div>
          ),
          url: "https://twitter.com/veda_verse",
        },
        {
          name: (
            <div className="flex items-center">
              <img src={linkedin} alt="LinkedIn" className="w-5 h-5 mr-2" />
              LinkedIn
            </div>
          ),
          url: "https://www.linkedin.com/company/vedaai-io/",
        },
      ],
    },
  ];

  return (
    <footer className="bg-[#274c77] text-white w-full py-8">
      <div className="container mx-auto w-full px-4">
        <div className="flex ml-10 justify-start sm:justify-center md:ml-0 mb-8">
          <img src={logo} alt="Logo" className="h-10" />
        </div>
        <div className="grid grid-cols-2 ml-10 md:ml-16 md:grid-cols-4 gap-8">
          {footerSections.map((section, index) => (
            <div key={index} className="mb-6 flex flex-col">
              <h6 className="text-lg sm:text-xl font-semibold mb-2">
                {section.title}
              </h6>
              <ul className="space-y-2">
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <a
                      href={link.url}
                      className="flex items-center text-white hover:underline text-sm sm:text-base"
                      target="_blank"
                      rel="noopener noreferrer">
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <p className="text-center text-white mt-8 text-sm sm:text-base">
          © {new Date().getFullYear()} VedaVerse. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
