import React from 'react';

const BlogQuote = ({ author, text, source }) => (
    <blockquote className="bg-gray-100 border-l-4 border-[#6096BA] p-4 my-4 rounded-r-lg">
      <p className="italic mb-2">{text}</p>
      <footer className="text-sm">
        <strong>{author}</strong> - <a href={source} className="text-[#274C77] hover:underline">{source}</a>
      </footer>
    </blockquote>
  );


export default BlogQuote;