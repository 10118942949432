import React from "react";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const ProfCard = ({
  name,
  image,
  designation,
  description,
  linkedIn,
  twitter,
  github,
}) => {
  return (
    <div className="bg-white rounded-lg border border-gray-200 overflow-hidden m-4 flex md:p-10 flex-col md:flex-row">
      <div className="md:w-1/3 flex justify-center items-center">
        <img
          className="h-72 object-contain object-center"
          src={image}
          alt={name}
        />
      </div>

      <div className="p-6 md:w-2/3 flex flex-col justify-center">
        <h2 className="text-2xl font-bold mb-2 text-gray-800">{name}</h2>
        <p className="text-gray-600 font-semibold text-sm mb-2">
          {designation}
        </p>
        <p className="text-gray-700 font-[550] text-base mb-4">{description}</p>
        <div className="flex space-x-4">
          {linkedIn && (
            <a
              href={linkedIn}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800">
              <FaLinkedin size={24} />
            </a>
          )}
          {twitter && (
            <a
              href={twitter}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-800 hover:text-gray-600">
              <FaSquareXTwitter size={24} />
            </a>
          )}
          {github && (
            <a
              href={github}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-800 hover:text-gray-600">
              <FaGithub size={24} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfCard;
