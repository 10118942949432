import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/logo/logo.png";
import GitHubIcon from "@mui/icons-material/GitHub";

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showGitHub, setShowGitHub] = useState(true);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { label: "Blog", path: "/blogs" },
    { label: "Hackathon", path: "/hackathon" },
    {
      label: "GitHub",
      path: "https://github.com/s-veda",
      external: true,
      icon: <GitHubIcon sx={{ fontSize: "1.5rem" }} />, 
    },
    {
      label: "Login",
      path: "https://platform.vedaverse.io",
      external: false,
    },
  ];

  useEffect(() => {
    const checkSpace = () => {
      const navbar = document.getElementById('navbar');
      if (navbar) {
        const availableSpace = navbar.offsetWidth - 200;
        const buttonSpace = 80 * 4; // Increased to account for the new Hackathon button
        setShowGitHub(availableSpace >= buttonSpace);
      }
    };

    checkSpace();
    window.addEventListener('resize', checkSpace);
    return () => window.removeEventListener('resize', checkSpace);
  }, []);

  const visibleMenuItems = isMobile && !showGitHub ? menuItems.filter(item => item.label !== "GitHub") : menuItems;

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: "white", boxShadow: "none" }}>
      <Toolbar
        id="navbar"
        style={{
          paddingLeft: isMobile ? "20px" : "60px",
          paddingRight: isMobile ? "20px" : "60px",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link
            to="/"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}>
            <img src={logo} alt="Logo" style={{ height: "40px" }} />
          </Link>
        </div>

        <Box sx={{ flexGrow: 1 }} />

        {isMobile ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {visibleMenuItems.map((item) => (
              <Button
                key={item.label}
                color="inherit"
                component={item.external ? "a" : Link}
                to={item.external ? undefined : item.path}
                href={item.external ? item.path : undefined}
                target={item.external ? "_blank" : undefined}
                rel={item.external ? "noopener noreferrer" : undefined}
                sx={{
                  color: "#274C77",
                  textTransform: "none",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "0.9rem",
                  padding: "6px",
                  minWidth: "auto",
                  marginLeft: "8px",
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}>
                {item.icon ? item.icon : item.label}
              </Button>
            ))}
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {menuItems.map((item) => (
              <Button
                key={item.label}
                color="inherit"
                component={item.external ? "a" : Link}
                to={item.external ? undefined : item.path}
                href={item.external ? item.path : undefined}
                target={item.external ? "_blank" : undefined}
                rel={item.external ? "noopener noreferrer" : undefined}
                sx={{
                  color: "#274C77",
                  textTransform: "none",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1rem", 
                  marginLeft: "16px",
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}>
                {item.icon ? item.icon : item.label}
              </Button>
            ))}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;