import React from "react";
import {
  Link,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  CardActions,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  maxWidth: "100%",
  margin: "auto",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  borderRadius: theme.shape.borderRadius * 2,
  border: "1px solid rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const StyledCardMediaContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "30%",
  minWidth: "200px",
  overflow: "hidden",
  flexShrink: 0,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
  },
}));

const StyledCardMedia = styled(CardMedia)({
  width: "100%",
  height: "100%",
  objectFit: "contain",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: theme.spacing(2),
  width: "70%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
  lineHeight: 1.3,
  fontSize: "1.5rem",
}));

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  lineHeight: 1.6,
}));

const AuthorTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "0.875rem",
  fontWeight: 600,
  marginRight: theme.spacing(2),
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  padding: theme.spacing(1, 2, 2),
  justifyContent: "space-between",
  alignItems: "center",
}));

export default function MultiActionAreaCard({
  image,
  date,
  title,
  description,
  author,
  blogUrl,
}) {
  return (
    <StyledCard>
      <CardActionArea
        component={Link}
        href={blogUrl}
        sx={{ display: "flex", flexDirection: "inherit", height: "100%" }}>
        <StyledCardMediaContainer>
          <StyledCardMedia component="img" image={image} alt={title} />
        </StyledCardMediaContainer>
        <ContentWrapper>
          <StyledCardContent>
            <TitleTypography variant="h5" component="h2">
              {title}
            </TitleTypography>
            <DescriptionTypography className="text-gray-900">
              {description}
            </DescriptionTypography>
          </StyledCardContent>
          <StyledCardActions>
            <Box display="flex" alignItems="center">
              <AuthorTypography className="text-gray-600">
                By {author}
              </AuthorTypography>
              <AuthorTypography className="text-gray-600" variant="subtitle2">
                {date}
              </AuthorTypography>
            </Box>
          </StyledCardActions>
        </ContentWrapper>
      </CardActionArea>
    </StyledCard>
  );
}
