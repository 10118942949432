import React, { useState } from "react";
import Navbar from "../components/nav_bar";
import Footer from "../components/footer";
import { Mail, Phone, MapPin } from "lucide-react";
import linkedin from "../assets/images/linkedin.png";
import twitter from "../assets/images/twitter.svg";
import facebook from "../assets/images/facebook.png";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("Form submitted:", formData);
  //   setFormData({ name: "", email: "", message: "" });
  // };

  return (
    <>
      <Helmet>
        <title>VedaVerse | Contact Us</title>
      </Helmet>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow ">
          <div className="container mx-auto px-4 py-16 max-w-5xl">
            <h1 className="text-4xl font-bold text-[#274c77] text-center mb-8">
              Contact Us
            </h1>
            <p className="text-center text-gray-600 mb-12 md:px-20">
              We'd love to hear from you! Whether you have a question, feedback,
              or just want to say hello, we're here for you. Choose a method
              below to get in touch with VedaVerse:
            </p>
            <div className="flex justify-center items-center ">
              <div className="bg-white p-8 rounded-lg border border-gray-300 pr-36">
                <h2 className="text-2xl font-semibold mb-6 text-[#274c77]">
                  Contact Information
                </h2>
                <div className="space-y-6">
                  <div className="flex items-start space-x-4">
                    <Mail className="h-6 w-6 text-[#274c77] mt-1" />
                    <div>
                      <h3 className="font-medium">Email</h3>
                      <p className="text-gray-600">hello@vedaai.io</p>
                    </div>
                  </div>
                  {/* <div className="flex items-start space-x-4">
                  <Phone className="h-6 w-6 text-[#274c77] mt-1" />
                </div>
                <div className="flex items-start space-x-4">
                  <MapPin className="h-6 w-6 text-[#274c77] mt-1" />
                </div> */}
                </div>
                <div className="mt-8">
                  <h3 className="text-lg font-medium mb-4 text-[#274c77]">
                    Follow Us
                  </h3>
                  <div className="flex space-x-4">
                    <a href="#" className="text-gray-600 hover:text-[#274c77]">
                      <img src={linkedin} alt="LinkedIn" className="h-6 w-6" />
                    </a>
                    <a href="#" className="text-gray-600 hover:text-[#274c77]">
                      <img src={twitter} alt="Twitter" className="h-6 w-6" />
                    </a>
                    <a href="#" className="text-gray-600 hover:text-[#274c77]">
                      <img src={facebook} alt="Facebook" className="h-6 w-6" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default ContactPage;
