import React from 'react';

const BlogHeader = ({ title, date, timestamp }) => (
  <header className="p-6 md:p-8 rounded-lg">
    <div className="max-w-4xl mx-auto">
    <div className="flex items-center space-x-4 text-sm md:text-base opacity-90">
        <div className="flex items-center">
          <span>{date}</span>
        </div>
        <div className="flex items-center">
          <span>{timestamp}</span>
        </div>
      </div>
      <h1 className="text-lg md:text-5xl lg:py-5 font-bold mb-4 leading-tight">{title}</h1>

    </div>
  </header>
);

export default BlogHeader;