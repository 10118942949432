import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import { Parallax } from "react-parallax";
import { motion } from "framer-motion";
import animationData from "../../assets/lottie/dashboard.json";
import backgroundImage from "../../assets/images/plain.jpg";
import HeroSectionSkeleton from "./hero_section_skeleton";
import { useMediaQuery } from "@mui/material";

const HeroSection = ({ onLearnMoreClick }) => {
  const isMobile = useMediaQuery("(max-width: 640px)");
  const isLandscape = useMediaQuery("(orientation: landscape)");
  const [loading, setLoading] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      transparent: true,
    },
  };

  useEffect(() => {
    const image = new Image();
    image.src = backgroundImage;
    image.onload = () => setLoading(false);
  }, []);

  if (loading) {
    return <HeroSectionSkeleton />;
  }

  return (
    <Parallax
      bgImage={backgroundImage}
      strength={500}
      bgImageStyle={{ objectFit: "cover", width: "100%", height: "100%" }}
    >
      <div className="relative w-full min-h-screen flex items-center justify-center text-white text-center">
        <div className="absolute inset-0 bg-black bg-opacity-10 z-10" />
        <div className="relative z-20 max-w-7xl mx-auto px-4 py-8 md:py-16">
          <h1 className="text-3xl md:text-5xl lg:text-7xl mb-4 md:mb-8 font-bold">Welcome to VedaVerse</h1>
          <p className="text-xl md:text-2xl font-light mb-8">A Universe Of Knowledge</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-left"
            >
              <h2 className="text-xl md:text-2xl lg:text-3xl mb-4 font-semibold">
                Bridge the gap to personalized, interactive, and real-world education.
              </h2>
              <p className="mb-6 text-base md:text-lg">
                Every section offers hands-on practice by contributing to open-source projects, providing the real-world exposure essential for success in any company—something traditional learning fails to provide.
              </p>
              <div className="flex flex-col sm:flex-row justify-center md:justify-start gap-4">
                <button
                  className="bg-[#274C77] text-white px-6 py-2.5 rounded-md shadow-sm hover:shadow-lg transition text-sm md:text-base"
                  onClick={() => window.open("https://forms.gle/JZPQjPZbcBE2xJZC6", "_blank")}
                >
                  JOIN PROGRAM
                </button>
                <button
                  className="border border-white text-white px-6 py-2.5 rounded-md transition text-sm md:text-base"
                  onClick={onLearnMoreClick}
                >
                  LEARN MORE
                </button>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className="flex justify-center items-center"
            >
              <Lottie
                options={defaultOptions}
                height={isMobile ? (isLandscape ? 200 : 300) : 400}
                width={isMobile ? (isLandscape ? 200 : 300) : 400}
              />
            </motion.div>
          </div>
        </div>
      </div>
    </Parallax>
  );
};

export default HeroSection;