import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import MultiActionAreaCard from "../components/blog/blog_card";
import highfive from "../assets/images/blog1_highfive.png";
import chatsphereImage from "../assets/images/chatsphere.svg"; // You'll need to add this image to your assets
import Navbar from "../components/nav_bar";
import { Helmet } from "react-helmet";

// Styled components
const HeaderBox = styled(Box)(({ theme }) => ({
  background: "#ffffff",
  padding: theme.spacing(12, 0),
  textAlign: "center",
}));

const MainTitle = styled(Typography)(({ theme }) => ({
  fontSize: "3.5rem",
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.4rem",
  marginBottom: theme.spacing(4),
  color: "#666",
}));

const BlogSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    maxWidth: "1200px",
  },
}));

const NewsletterSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(6, 0),
  textAlign: "center",
  marginTop: theme.spacing(6),
}));

const NewsletterTitle = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const NewsletterSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  marginBottom: theme.spacing(4),
  color: "#666",
}));

const NewsletterForm = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
  flexWrap: "wrap",
}));

const blogPosts = [
  {
    image: chatsphereImage,
    date: "August 31st, 2024",
    title: "ChatSphere-1: The Open-Source AI Search Engine",
    description:
      "Discover ChatSphere-1, our innovative open-source AI search engine that integrates all major language models into a single, streamlined platform. Learn how it's simplifying AI access, cutting costs, and empowering students through open-source contributions.",
    author: "VedaVerse Team",
    blogUrl: "/blog/introducing-chatsphere-1",
  },
  {
    image: highfive,
    date: "July 27th, 2024",
    title: "Introducing VedaVerse",
    description:
      "Welcome to VedaVerse - A Universe of Knowledge! Discover how AI can transform education and how we're dedicated to making learning practical, engaging, and accessible for everyone while tackling the challenges faced by students in the current education system.",
    author: "Ananya Pappula",
    blogUrl: "/blog/introducing-vedaverse",
  },
];

function BlogHome() {
  return (
    <>
      <Helmet>
        <title>VedaVerse | Blogs</title>
      </Helmet>
      <Navbar />
      <HeaderBox>
        <Container maxWidth="sm">
          <MainTitle variant="h1">VedaVerse</MainTitle>
          <Subtitle variant="h2">
            Discover the latest insights and stories from VedaVerse
          </Subtitle>
        </Container>
      </HeaderBox>

      <StyledContainer>
        <BlogSection>
          <Typography variant="h5" color="textSecondary" paragraph>
            LATEST
          </Typography>
          <Grid container spacing={4}>
            {blogPosts.map((post, index) => (
              <Grid item key={index} xs={12} sm={12} md={16}>
                <MultiActionAreaCard
                  image={post.image}
                  date={post.date}
                  title={post.title}
                  description={post.description}
                  author={post.author}
                  blogUrl={post.blogUrl}
                />
              </Grid>
            ))}
          </Grid>
        </BlogSection>

        <NewsletterSection>
          <Container maxWidth="sm">
            <NewsletterTitle variant="h4">Stay Updated</NewsletterTitle>
            <NewsletterSubtitle variant="body1">
              Sign up for our newsletter and receive the latest updates and
              stories from VedaVerse.
            </NewsletterSubtitle>
            <NewsletterForm>
              <TextField
                variant="outlined"
                placeholder="Enter your email"
                type="email"
                size="small"
                fullWidth
              />
              <Button variant="contained" color="primary" size="large">
                Subscribe
              </Button>
            </NewsletterForm>
          </Container>
        </NewsletterSection>
      </StyledContainer>
    </>
  );
}

export default BlogHome;
