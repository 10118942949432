import React from "react";
import { motion, useScroll } from "framer-motion";
import BlogContainer from "../../components/blog/blog_container";
import BlogHeader from "../../components/blog/blog_header";
import BlogSection from "../../components/blog/blog_section";
import BlogQuote from "../../components/blog/blog_quote";
import highfive from "../../assets/images/blog1_highfive.png";
import robot from "../../assets/images/blog1_robo.png";
import student from "../../assets/images/blog1_student.png";
import prof from "../../assets/images/prof.png";
import Navbar from "../../components/nav_bar";
import { Helmet } from "react-helmet";

function IntroductoryBlog() {
  const { scrollYProgress } = useScroll();

  const menuitems = [
    "Our Vision",
    "Identifying Core Problems and Crafting Solutions",
    "About VedaVerse",
    "How it Works?",
    "Challenges Faced",
    "Embracing AI for a Better Future",
    "Our Philosophy",
  ];

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.5, delay: 0.2 },
    },
  };

  const ListItem = ({ children }) => (
    <li className="flex items-start mb-4 text-lg">
      <div>{children}</div>
    </li>
  );

  return (
    <>
      <Helmet>
        <title>Introducing VedaVerse</title>
      </Helmet>
      <Navbar />
      <motion.div
        className="fixed top-0 left-0 right-0 h-1.5 bg-[#4382c9] z-50"
        style={{ scaleX: scrollYProgress }}
      />

      <BlogHeader
        title="Introducing VedaVerse"
        date="July 27th, 2024"
        timestamp="5 min read"
      />
      <img src={prof} alt="prof" className="h-[30vh] mx-auto" />

      <BlogContainer menuitems={menuitems}>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={sectionVariants}
          className="border-b border-gray-200 last:border-b-0">
          <BlogSection title="Our Vision">
            <p className="text-lg">
              At VedaVerse, we believe that learning is a lifelong adventure,
              far beyond the confines of age and formal education. This belief,
              shaped by our shared experiences, fuels our mission to reshape
              education and make it more effective and accessible for everyone.
              Our journey isn't just another business venture - it's a heartfelt
              commitment to transforming the way education impacts our lives.
            </p>
          </BlogSection>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={sectionVariants}
          className="border-b border-gray-200 last:border-b-0">
          <BlogSection title="Identifying Core Problems and Crafting Solutions">
            <p className="text-lg">
              We first had this idea in mid-October 2023. By March 2024, we had
              developed our first MVP project. The results didn't make us happy
              so we had to pour countless hours into brainstorming and
              prototyping, focusing on how AI could personalize education. This
              relentless effort and innovation led to the birth of our current
              vision - VedaVerse!
            </p>{" "}
            <br />
            <p className="text-lg">
              We have conducted research through the feedback from over 500
              students from diverse backgrounds, using which our AI professor
              has helped in identifying several key challenges in the current
              education system. The most significant issues include:
            </p>{" "}
            <br />
            <ul className="space-y-2 mb-6 text-lg">
              <ListItem>
                <strong>Outdated Syllabus:</strong> We're stuck learning stuff
                that's as old as a flip phone! The material just doesn't keep up
                with the latest industry trends and tech.
              </ListItem>
              <ListItem>
                <strong>Student Diversity:</strong> Students arrive with a
                variety of backgrounds and levels of understanding, making it
                challenging to ensure that everyone progresses at the same pace.
              </ListItem>
              <ListItem>
                <strong>Incompetent Teaching Methods:</strong> Many teachers in
                educational institutions struggle with limited resources and
                tight schedules, making it challenging to engage students
                effectively and provide individualized attention.
              </ListItem>
              <ListItem>
                <strong>Mismatch with Job Market:</strong> What we learn in
                school often doesn't align with what employers are seeking,
                leaving students unprepared for real-world challenges.
              </ListItem>
            </ul>
            <motion.img
              className="w-[80%] mx-auto rounded-2xl"
              src={student}
              alt="Identifying student's problems"
              variants={imageVariants}
            />
          </BlogSection>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={sectionVariants}
          className="border-b border-gray-200 last:border-b-0">
          <BlogSection title="About VedaVerse">
            <p className="text-lg">
              In response to these challenges, we have developed VedaVerse with
              some fantastic features:
            </p>
            <ul
              style={{ listStyleType: "none" }}
              className="list-disc list-inside text-lg">
              <ListItem>
                <strong>Autonomous AI Professor:</strong> Our AI professor is
                like the dream teacher you've always wanted! He adapts to each
                student's knowledge level, creating customized learning paths,
                generating real-time interactive videos, and responding to
                queries to ensure a consistent and effective learning
                experience.
              </ListItem>
              <ListItem>
                <strong>Certificates of Proof:</strong> Our certificates
                validate the work and projects that the students complete during
                the course. These certificates detail the student's
                accomplishments, boosting their employment prospects.
              </ListItem>
              <ListItem>
                <strong>Community Collaboration:</strong> We offer a platform
                for students to interact, collaborate, and learn from each
                other, fostering motivation and support. It's like having a
                study buddy from around the world!
              </ListItem>
              <ListItem>
                <strong>Multilingual Support:</strong> Our AI Professor can
                explain concepts in over 20 languages, making learning more
                accessible and natural for a diverse audience.
              </ListItem>
              <ListItem>
                <strong>Focus on Practical Experience:</strong> VedaVerse
                emphasizes practical experience, encouraging students to build
                projects with real-life impact, preparing them for the
                professional world.
              </ListItem>
            </ul>
          </BlogSection>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={sectionVariants}
          className="border-b border-gray-200 last:border-b-0">
          <BlogSection title="How It Works?">
            <p className="text-lg">
              <strong>Engage with the Professor:</strong> Our AI professor
              understands students through their responses and the conversation
              as a whole.
            </p>
            <p className="text-lg">
              <strong>Curated Curriculum: </strong> The professor curates a
              curriculum personalized based on the student's skill set and other
              metrics.
            </p>
            <p className="text-lg">
              <strong>Interactive Learning: </strong> The professor explains
              topics with multimodal capabilities (including audio, video, and
              image input & output), making it feel just like connecting with a
              real human on a video call.
            </p>
            <p className="text-lg">
              <strong>Practical Projects: </strong> After each section, students
              complete practical work, focusing on open-source projects where
              they can collaborate with the professor to gain real-world
              exposure.
            </p>
          </BlogSection>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={sectionVariants}
          className="border-b border-gray-200 last:border-b-0">
          <BlogSection title="Challenges Faced">
            <p className="text-lg">
              Venturing into a relatively unexplored field of AI in education
              came with many challenges. Even getting to results which were
              seemingly simple involved a lot of effort. Some of these
              challenges are:
            </p>{" "}
            <br />
            <ul
              style={{ listStyleType: "none" }}
              className="list-disc list-inside text-lg">
              <ListItem>
                <strong>AI Hallucination:</strong> A key challenge was stopping
                AI from producing information that appears accurate but is
                actually wrong. We had to build strong safeguards and keep
                improving our algorithms for reliable results.
              </ListItem>
              <ListItem>
                <strong>Adapting Learning Styles:</strong> Customizing education
                to fit each student's learning style required a lot of research
                and adjustments. The AI system had to keep evolving to meet
                various needs effectively.
              </ListItem>
              <ListItem>
                <strong>Innovative Teaching Methods:</strong> Developing and
                implementing new teaching methods involved rigorous research and
                testing. Each method had to be refined to effectively engage
                students and enhance their learning experience, requiring a deep
                understanding of educational theories and technological
                advancements.
              </ListItem>
              <ListItem>
                <strong>UI/UX Design:</strong> Designing an easy-to-use,
                attractive, and effective interface was also challenging. We
                wanted students to navigate the platform smoothly and stay
                focused on their studies.
              </ListItem>
            </ul>
            <motion.img
              className="w-[80%] mt-12 mx-auto rounded-2xl"
              src={robot}
              alt="Challenges faced by VedaVerse"
              variants={imageVariants}
            />
          </BlogSection>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={sectionVariants}
          className="border-b border-gray-200 last:border-b-0">
          <BlogSection title="Embracing AI for a Better Future">
            <p className="text-lg">
              When calculators first came out, teachers worried they'd hold
              students back. Instead, they helped us create amazing new
              technologies. AI is no different! It's here to stay, and we should
              teach students to use it wisely. Embracing AI can lead to amazing
              breakthroughs!
            </p>
            <p className="text-lg">
              Here are some things which domain experts and many other
              innovators believe AI can do:
            </p>
            <BlogQuote
              author="Sal Khan"
              text="AI has the potential to democratize education. Quality education resources can be accessible to anyone with an internet connection, leveling the playing field."
              source="Gates Notes, 2023"
            />
            <BlogQuote
              author="Sam Altman"
              text="The difference between classroom education and one-on-one tutoring is like two standard deviations - an unbelievable difference. Most people just can't afford one-on-one tutoring... If we can combine one-on-one tutoring to every child with the things that only a human teacher can provide, the sort of support, I think that combination is just going to be incredible for education."
              source="Melbourne Business School, 2023"
            />

            <BlogQuote
              author="Andrew Ng"
              text="Just as electricity transformed almost everything 100 years ago, today I actually have a hard time thinking of an industry that I don't think AI will transform in the next several years."
              source="Stanford Graduate School of Business, 2017"
            />
            <motion.img
              className="w-[80%] mx-auto rounded-2xl"
              src={highfive}
              alt="Embracing AI for a better future"
              variants={imageVariants}
            />
          </BlogSection>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={sectionVariants}
          className="border-b border-gray-200 last:border-b-0">
          <BlogSection title="Our Philosophy">
            <p className="text-lg">
              At VedaVerse, we live by our philosophy - "Learn without limits."
              As technology advances, so do we. We are committed to staying
              ahead of the curve and look forward to collaborating with you.
            </p>
            <p className="text-lg">
              Join us in shaping the future of education, where learning is more
              fun, engaging, and impactful than ever before!
            </p>
          </BlogSection>
        </motion.div>
        <footer className="p-8 rounded-b-lg shadow-lg ">
          <h3 className="text-xl font-semibold mb-4">References</h3>
          <ul
            style={{ listStyleType: "none" }}
            className="list-disc list-inside mb-4 space-y-2 text-lg">
            <li>
              Sal Khan on AI:{" "}
              <span>
                <a
                  className="text-[#274C77] font-bold hover:underline"
                  href="https://www.gatesnotes.com/Unconfuse-Me-podcast-with-guest-Sal-Khan">
                  Gates Notes (2023).
                </a>
              </span>
            </li>
            <li>
              Sam Altman on AI in Education:{" "}
              <span>
                <a
                  className="text-[#274C77] font-bold hover:underline"
                  href="https://mbs.edu/news/Why-OpenAI-CEO-Sam-Altman-is-excited-about-the-future-of-education">
                  Melbourne Business School (2023).{" "}
                </a>
              </span>
            </li>
            <li>
              Andrew Ng on AI is the new Electricity:{" "}
              <span>
                <a
                  className="text-[#274C77] font-bold hover:underline"
                  href="https://www.gsb.stanford.edu/insights/andrew-ng-why-ai-new-electricity">
                  Stanford Graduate School of Business (2017).{" "}
                </a>
              </span>
            </li>
          </ul>
          <p className="text-lg">
            Share your feedback at{" "}
            <a
              href={`mailto:hello@vedaai.io`}
              className="text-[#274C77] font-bold hover:underline">
              hello@vedaai.io
            </a>
          </p>
        </footer>
      </BlogContainer>
    </>
  );
}

export default IntroductoryBlog;
